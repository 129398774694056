import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent }   from './app.component';
import { AboutComponent } from './about/about.component';
import { PortfolioModule } from './portfolio/portfolio.module';
import { ContactComponent } from './contact/contact.component';

const routes: Routes = [
  { 
    path: 'about',
    component: AboutComponent
  },
  { 
    path: 'portfolio',
    redirectTo: 'portfolio'
  },
  { 
    path: 'contact',
    component: ContactComponent
  },
  {
    path: '',
    redirectTo: 'about',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'about',
    pathMatch: 'full'
  }
];

@NgModule({
  // trying to fix 404 issue on reload
  imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'top' } )], // { onSameUrlNavigation: 'reload' }
  exports: [RouterModule],
  bootstrap: [AppComponent]
})
export class AppRoutingModule { }
