<div class="fill-window d-flex justify-content-center align-items-center">
    <div class="m-auto">
        <h1 class="font-weight-bold text-center">Give me a shout!</h1>
        <form [formGroup]="contactForm" (ngSubmit)="submit()">
            <div class="form-group">
                <label for="name">Name</label>
                <input type="name" class="form-control" id="name" formControlName="name" placeholder="John Appleseed" [ngClass]="{ 'is-invalid': submitted && contactForm.controls.name.errors && (contactForm.controls.name.dirty || contactForm.controls.name.touched) }">
                <div class="invalid-feedback" *ngIf="submitted && contactForm.controls.name.errors">
                    <div *ngIf="contactForm.controls.name.errors.required">Name is required.</div>
                    <div *ngIf="contactForm.controls.name.errors.pattern">Names can only include letters, hyphens or apostrophes.</div>
                </div>
            </div>
            <div class="form-group">
                <label for="email">Email</label>
                <input type="email" class="form-control" id="email" formControlName="email" placeholder="appleseed@email.com" [ngClass]="{ 'is-invalid': submitted && contactForm.controls.email.errors && (contactForm.controls.email.dirty || contactForm.controls.email.touched) }">
                <div class="invalid-feedback" *ngIf="submitted && contactForm.controls.email.errors">
                    <div *ngIf="contactForm.controls.email.errors.required">Email is required.</div>
                    <div *ngIf="contactForm.controls.email.errors.email">Email is invalid.</div>
                </div>
            </div>
            <div class="form-group">
                <label for="services">Services Needed</label>
                <select class="custom-select mr-sm-2" id="services">
                    <option selected>Choose...</option>
                    <option value="1">Web Design and Development</option>
                    <option value="2">Stipple Portraits</option>
                    <option value="3">Mobile Development</option>
                    <option value="4">Sculptures</option>
                    <option value="5">Java Development</option>
                </select>
            </div>
            <div class="form-group">
                <label for="message">Message</label>
                <textarea class="form-control" id="message" formControlName="message" rows="3" [ngClass]="{ 'is-invalid': submitted && contactForm.controls.message.errors && (contactForm.controls.email.dirty || contactForm.controls.email.touched) }"></textarea>
                <div class="invalid-feedback" *ngIf="submitted && contactForm.controls.message.errors">
                    <div *ngIf="contactForm.controls.message.errors.pattern">Messages cannot include <, >, /, \.</div>
                </div>
            </div>
            <button type="submit" class="btn btn-block btn-gradient">SEND</button>
        </form>
    </div>
</div>