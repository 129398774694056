<div id="welcome">
    <div class="fill-window d-flex justify-content-center align-items-center text-center">
        <div class="m-auto">
            <h1 class="intro"><span class="hello orange-gradient-bg">Hello.</span> <br> I’m Joanna!</h1>
            <p>
                I recently graduated from Dalhousie University with a Bachelor of Computer Science (BCS) focused on user interface design and evaluation. <br>
                You may find me drawing portraits or picking out stylistic inconsistencies on restaurant menus or billboards.<br>
                <a href="https://github.com/jbstks" class="btn btn-link"><fa-icon [icon]="faGithub"></fa-icon></a> <a href="https://www.linkedin.com/in/jbstks/" class="btn btn-link"><fa-icon [icon]="faLinkedinIn"></fa-icon></a>
            </p>
            <!--<a href="#resume" class="btn btn-link"><fa-icon [icon]="faChevronDown"></fa-icon></a>-->
        </div>
    </div>
</div>

<div id="resume">

    <div class="mb-5 container">
        <h4>EDUCATION</h4>
        <hr>
        <div class="row no-gutters">
            <div class="col-md-1 jobedu-icon">
                <!-- Taken from Dalhousie's research page: https://www.dal.ca/dept/communicationsandmarketing/brand.html -->
                <img src="assets/dal-logo.png" class="dal-logo pt-1" alt="Dalhousie University Logo">
            </div>
            <div class="col-md-11">
                <div class="info">
                    <div class="d-flex flex-row justify-content-between flex-wrap">
                        <div class="edu-info">
                            <h5>Dalhousie University</h5>
                            <h6>Bachelor of Computer Science (BCS), User Experience Design and Evaluation</h6>
                        </div>
                        <div class="edu-desc">
                            <p class="m-0">September 2015 - December 2019</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="mb-5 container" id="skills">
        <h4>SKILLS</h4>
        <hr>
        <!--<div class="row no-gutters">
            <div class="col-1">

            </div>-->
            <div class="skills d-flex flex-row flex-wrap">
                <span class="badge badge-secondary orange-gradient-bg mr-3 mb-2 pr-3 pl-3 p-2">HTML</span>
                <span class="badge badge-secondary orange-gradient-bg mr-3 mb-2 pr-3 pl-3 p-2">CSS</span>
                <span class="badge badge-secondary orange-gradient-bg mr-3 mb-2 pr-3 pl-3 p-2">Java</span>
                <span class="badge badge-secondary orange-gradient-bg mr-3 mb-2 pr-3 pl-3 p-2">PHP</span>
                <span class="badge badge-secondary orange-gradient-bg mr-3 mb-2 pr-3 pl-3 p-2">JavaScript</span>
                <span class="badge badge-secondary orange-gradient-bg mr-3 mb-2 pr-3 pl-3 p-2">AngularJS</span>
                <span class="badge badge-dark mr-3 mb-2 pr-3 pl-3 p-2">UI Design</span>
            </div>
        <!--</div>-->
    </div>

    <div class="pb-5 container">
        <h4>WORK EXPERIENCE</h4>
        <hr>
        <!--<div class="mb-3" *ngFor="let job of jobs">
            <div class="row no-gutters">
                <div class="col-md-1 jobedu-icon">
                    job.icon
                </div>
                <div class="col-md-11 info">
                    <div class="row no-gutters">
                        <div class="col-md-6 job-info">
                            <h5>{{ job.name }}</h5>
                            <h6>{{ job.role }}</h6>
                        </div>
                        <div class="col-md-6 job-year">
                            <p>{{ job.duration }}</p>
                        </div>
                    </div>
                    <p class="m-0">
                        {{ job.description }}
                    </p>
                </div>
            </div>
        </div>-->

        <div class="mb-5">
            <div class="row no-gutters">
                <div class="col-md-1 jobedu-icon">
                    <fa-icon class="apple-icon" [icon]="faApple"></fa-icon>
                </div>
                <div class="col-md-11 info">
                    <div class="row no-gutters">
                        <div class="col-md-6 job-info">
                            <h5>Apple</h5>
                            <h6>Technical Expert</h6>
                        </div>
                        <div class="col-md-6 job-year">
                            <p>March 2017 - Present</p>
                        </div>
                    </div>
                    <p class="m-0">
                        Providing speedy repairs and face-to-face technical support by breaking down a solution for the customer in the simplest way possible to ensure they understand why something is happening and how we are going to fix it.<br>
                        Educating my team members with my technical expertise to ensure they provide their customers with the best experience.
                    </p>
                </div>
            </div>
        </div>
        <div class="">
            <div class="row no-gutters">
                <div class="col-md-1 jobedu-icon">
                    <!-- Taken from Dalhousie's research page: https://www.dal.ca/dept/communicationsandmarketing/brand.html -->
                    <img src="assets/dal-logo.png" class="dal-logo pt-1" alt="Dalhousie University Logo">
                </div>
                <div class="col-md-11 info">
                    <div class="row no-gutters">
                        <div class="col-md-6 job-info">
                            <h5>Dalhousie University</h5>
                            <h6>Undergraduate Teaching Assistant</h6>
                        </div>
                        <div class="col-md-6 job-year">
                            <p>September 2017 - December 2019</p>
                        </div>
                    </div>
                    <p class="m-0">
                        Taught labs between ~40-100 students either on my own or with other teaching assistants to provide students with additional help or hands-on experience with the content they learned in lecture.<br>
                        Provided extra help hours for students to ensure they leave with a deeper understanding of the content than when they came in.
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="pb-5 container">
        <h4>CERTIFICATIONS</h4>
        <hr>

        <div class="mb-5">
            <div class="row no-gutters">
                <div class="col-md-1 jobedu-icon">
                    <fa-icon class="apple-icon" [icon]="faApple"></fa-icon>
                </div>
                <div class="col-md-11 info">
                    <div class="row no-gutters">
                        <div class="col-md-6 job-info">
                            <h5>Apple Certified Macintosh Technician (ACMT)</h5>
                            <h6>Apple</h6>
                        </div>
                        <div class="col-md-6 job-year">
                            <p>May 2020</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mb-5">
            <div class="row no-gutters">
                <div class="col-md-1 jobedu-icon">
                    <!-- Taken from Dalhousie's research page: https://www.dal.ca/dept/communicationsandmarketing/brand.html -->
                    <img src="assets/dal-logo.png" class="dal-logo pt-1" alt="Dalhousie University Logo">
                </div>
                <div class="col-md-11 info">
                    <div class="row no-gutters">
                        <div class="col-md-6 job-info">
                            <h5>Certificate in Communication Technology and Cyber Security</h5>
                            <h6>Dalhousie University</h6>
                        </div>
                        <div class="col-md-6 job-year">
                            <p>December 2019</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mb-5">
            <div class="row no-gutters">
                <div class="col-md-1 jobedu-icon">
                    <!-- Taken from Dalhousie's research page: https://www.dal.ca/dept/communicationsandmarketing/brand.html -->
                    <img src="assets/dal-logo.png" class="dal-logo pt-1" alt="Dalhousie University Logo">
                </div>
                <div class="col-md-11 info">
                    <div class="row no-gutters">
                        <div class="col-md-6 job-info">
                            <h5>Certificate in User Interface Design and Evaluation</h5>
                            <h6>Dalhousie University</h6>
                        </div>
                        <div class="col-md-6 job-year">
                            <p>December 2019</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="">
            <div class="row no-gutters">
                <div class="col-md-1 jobedu-icon">
                    <fa-icon class="apple-icon" [icon]="faApple"></fa-icon>
                </div>
                <div class="col-md-11 info">
                    <div class="row no-gutters">
                        <div class="col-md-6 job-info">
                            <h5>Apple Certified iOS Technician (ACiT)</h5>
                            <h6>Apple</h6>
                        </div>
                        <div class="col-md-6 job-year">
                            <p>February 2019</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>