export const jobs = [
    {
      name: 'Apple',
      role: 'Technical Expert',
      duration: 'March 2017 - Present',
      icon: '<fa-icon class="apple-icon" [icon]="faApple"></fa-icon>',
      description: 'Providing speedy repairs and face-to-face technical support by breaking down a solution for the customer in the simplest way possible to ensure they understand why something is happening and how we are going to fix it.\nEducating my team members with my technical expertise to ensure they provide their customers with the best experience.'
    },
    {
      name: 'Dalhousie University',
      role: 'Undergraduate Teaching Assistant',
      duration: 'September 2017 - December 2019',
      icon: '<img src="assets/dal-logo.svg" class="dal-logo pt-1" alt="Dalhousie University Logo">',
      description: 'Taught labs between ~40-100 students either on my own or with other teaching assistants to provide students with additional help or hands-on experience with the content they learned in lecture.\nProvided extra help hours for students to ensure they leave with a deeper understanding of the content than when they came in.'
    }
  ];