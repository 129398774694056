<div class="container content-container">
    <div class="text-center mb-5">
        <h1>ClaimIt</h1>
        <h6>Streamlining the process of filing a claim with the Nova Scotia Small Claims Court</h6>
    </div>

    <h4>PROTOTYPE</h4>
    <hr>
    <span>This prototype assumes a scenario where you are the tenant filing a claim against your landlord regarding the denial of returning your security deposit.</span>
    <div class="row mb-5">
        <div class="col-lg-6">
            <img src="assets/claimit/1.1-LoginScreen-onLaunch.png" alt="ClaimIt Login Screen">
            <h6 class="mt-1 mb-0">1.1 - Sign In</h6>
            <small class="text-muted">Entering credentials for login.</small>
        </div>
        <div class="col-lg-6">
            <img src="assets/claimit/1.2-LoginScreen-createAccount.png" alt="ClaimIt Create Account">
            <h6 class="mt-1 mb-0">1.2 - Create an Account</h6>
            <small class="text-muted">Entering credentials to create a new account.</small>
        </div>
    </div>
    <div class="row mb-5">
        <div class="col-lg-6">
            <img src="assets/claimit/2.1-GettingStarted.png" alt="ClaimIt Getting Started">
            <h6 class="mt-1 mb-0">2.1 - Starting a Claim</h6>
            <small class="text-muted">Choosing the nature of the claim to figure out the next steps.</small>
        </div>
        <div class="col-lg-6">
            <img src="assets/claimit/3.1-Position.png" alt="ClaimIt Position in Case">
            <h6 class="mt-1 mb-0">3.1 - Position in Case</h6>
            <small class="text-muted">(SCENARIO SPECIFIC) Getting the position of the claimant.</small>
        </div>
    </div>
    <div class="row mb-5">
        <div class="col-lg-6">
            <img src="assets/claimit/4.1-PersonalInfo.png" alt="ClaimIt Personal Information">
            <h6 class="mt-1 mb-0">4.1 - Personal Information</h6>
            <small class="text-muted">To ensure the personal information from the signed in account is correct.</small>
        </div>
        <div class="col-lg-6">
            <img src="assets/claimit/5.1-LandlordInfo.png" alt="ClaimIt Landlord Information">
            <h6 class="mt-1 mb-0">5.1 - Landlord Information</h6>
            <small class="text-muted">(SCENARIO SPECIFIC) To retrieve the information of the landlord, who is the defendant in this scenario.</small>
        </div>
    </div>
    <div class="row mb-5">
        <div class="col-lg-6">
            <img src="assets/claimit/6.1-ReasonForDenial.png" alt="ClaimIt Reason for Denial">
            <h6 class="mt-1 mb-0">6.1 - Reason for Denial</h6>
            <small class="text-muted">(SCENARIO SPECIFIC) Filling in claim information.</small>
        </div>
        <div class="col-lg-6">
            <img src="assets/claimit/7.1-PartialorFullDenial.png" alt="ClaimIt Partial or Full Denial">
            <h6 class="mt-1 mb-0">7.1 - Partial or Full Denial</h6>
            <small class="text-muted">(SCENARIO SPECIFIC) Filling in claim information</small>
        </div>
    </div>
    <div class="row mb-5">
        <div class="col-lg-6">
            <img src="assets/claimit/8.1-PartialorFullReturn.png" alt="ClaimIt Partial or Full Return">
            <h6 class="mt-1 mb-0">7.2 - Partial or Full Return</h6>
            <small class="text-muted">(SCENARIO SPECIFIC) Filling in claim information.</small>
        </div>
        <div class="col-lg-6">
            <img src="assets/claimit/5.1-LandlordInfo.png" alt="ClaimIt Landlord Information">
            <h6 class="mt-1 mb-0">5.1 - Landlord Information</h6>
            <small class="text-muted">(SCENARIO SPECIFIC) To retrieve the information of the landlord, who is the defendant in this scenario.</small>
        </div>
    </div>
   
</div>