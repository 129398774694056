import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DigitalnsComponent } from './digitalns/digitalns.component';
import { ClaimitComponent } from './claimit/claimit.component';
import { PortfolioListComponent } from './portfolio-list/portfolio-list.component';
import { HereattendanceComponent } from './hereattendance/hereattendance.component';
import { ArtworkComponent } from './artwork/artwork.component';
import { MaximegalonlineComponent } from './maximegalonline/maximegalonline.component';


const portfolioRoutes: Routes = [
  { path: 'portfolio',  component: PortfolioListComponent },
  { path: 'portfolio/claimit', component: ClaimitComponent },
  { path: 'portfolio/digitalns', component: DigitalnsComponent },
  { path: 'portfolio/hereattendance', component: HereattendanceComponent },
  { path: 'portfolio/artwork', component: ArtworkComponent },
  { path: 'portfolio/maximegalonline', component: MaximegalonlineComponent }
];

@NgModule({
  imports: [
    RouterModule.forChild(portfolioRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class PortfolioRoutingModule { }
