<div class="container content-container">
    <div class="text-center mb-5">
        <h1>Digital Nova Scotia</h1>
        <h6>Improving the design of the Digital Nova Scotia website by analyzing one of the webpages</h6>
    </div>

    <h4>DEFICIENCIES</h4>
    <hr>
    <div class="row mb-5">
        <div class="col-lg-6">
            <ul>
                <li>There is no need for the social network links to be in multiple places on one page. It creates a very cluttered environment.</li>
                <li>The member login link is a placeholder and does not work, making it redundant. However, if we assume it was working correctly, the login can be centralized. If board members and general members could sign in from the same webpage, it would remove the need for two separate login buttons.</li>
                <li>All of the content in ‘About’ and ‘Contact’ can be merged and do not need separate spots in the navigation menu. This will save space and declutter the navigation menu. Also, it is rather common enough to not have a ‘Home’ link in the navigation in favour of the website logo/name being a link to the homepage.</li>
                <li>This breadcrumb is rather useless as there are not that many levels in the sitemap. The user can also go back by clicking ‘Home’ or the logo in the navigation bar.</li>
                <li>Ideally the user should be able to subscribe to Digital NS’ ‘Weekly Digest’ and mail-outs by adding an email field prompt (this is also addressed in <span class="list-num">10</span>).</li>
                <li>The sign up forms are embedded using a frame, which does not function correctly on Safari. It is also very cluttered and overwhelming with information. It should be summed down to the need-to-know information.</li>
                <li>There is a massive waste of space between the form and the footer.</li>
                <li>There is no need for the ‘Latest News’ and ‘Upcoming Events’ in the footer as there is a dedicated page for news, and events, respectively.</li>
                <li>This Twitter widget rarely works. Even when it does, it looks odd and out of place. It could be moved elsewhere or, preferably, removed altogether as the social network links are already provided.</li>
                <li>The subscribe button does not make sense, because it takes the user to the "Join Today" (where we already are in this case) which is confusing. On this page, it does say, <i>“Note: If you wish to subscribe to our Weekly Digest and mail-outs as a non-member simply choose Guest Subscriber (Non-Member) as your membership level in the online application.”.</i> This could be done in a simpler way, by allowing the user to enter their email (this is also addressed in <span class="list-num">5</span>). The ‘Subscribe’ button is also located in several other pages as well as being in the footer.</li>
            </ul>
        </div>
        <div class="col-lg-6">
            <img src="assets/digitalns/original-wireframe-noted.png">
        </div>
    </div>

    <h4>SOLUTIONS</h4>
    <hr>
    <div class="row mb-5">
        <div class="col-lg-6">
            <img src="assets/digitalns/improved-wireframe.png" class="pb-3">
        </div>
        <div class="col-lg-6 my-auto">
            <ul>
                <li>Removed the whole bar as the social network links did not need to be there.</li>
                <li>Login buttons were moved to the navigation bar.</li>
                <li>Merged ‘About’ and ‘Contact’ by leaving just ‘About’ on the navigation menu. Also added a single button for login and join, respectively. Also deleted the ‘Home’ link.</li>
                <li>Removed the whole breadcrumb bar.</li>
                <li>Removed the note about subscribing to their ‘Weekly Digests’ and mail-outs by registering as a ‘guest subscriber’.</li>
                <li>Ideally the user would be able to input their email into a field.</li>
                <li>Reorganized the form to show membership levels in a much more digestible format. Instead of bombarding the user with paragraphs of information, it shows the necessities. It would also not be in a frame.</li>
                <li>Removed the unnecessary abundance of white space.</li>
                <li>Removed ‘Latest News’ and ‘Upcoming Events’ out of the footer.</li>
                <li>Removed the Digital Nova Scotia’s Twitter feed widget out of the footer.</li>
                <li>Removed the ‘Subscribe’ button out of the footer (cut out all of the unnecessary information in the footer).</li>
            </ul>
        </div>
    </div>
</div>