<div id="portfolio" class="fade-out">
    <div class="row d-flex justify-content-center pt-3">
        <div class="col-sm-6 col-lg-4 mb-4">
            <a routerLink="hereattendance" class="text-decoration-none">
                <div class="card h-100"> <!--style="background-color: rgb(64,80,181); color: #ffffff;">-->
                    <img class="card-img-top d-block" src="assets/here-logo.png" alt="Here! Logo">
                    <div class="card-body d-flex flex-column">
                        <h4 class="mb-0">Here!</h4>
                        <p class="card-text">Manage your classroom attendance.</p>
                        <div class="mt-auto">
                            <span class="badge badge-pill badge-secondary mr-1">Android</span>
                            <span class="badge badge-pill badge-secondary">Java</span>
                        </div>
                    </div>
                    <div class="card-overlay">
                        <h5>View More Info</h5>
                    </div>
                </div>
            </a>
        </div>
        <div class="col-sm-6 col-lg-4 mb-4">
            <a routerLink="maximegalonline" class="text-decoration-none">
                <div class="card card-link h-100"> <!--style="background-color: rgb(48,63,159); color: #ffffff;">-->
                    <img class="card-img-top d-block" src="assets/umaximegalon-logo.png" alt="uMaximegalon Logo">
                    <div class="card-body d-flex flex-column">
                        <h4 class="mb-0">MaximegalOnline</h4>
                        <p class="card-text">Course management at uMaximegalon.</p>
                        <div class="mt-auto">
                            <span class="badge badge-pill badge-secondary mr-1">Android</span>
                            <span class="badge badge-pill badge-secondary">Java</span>
                        </div>
                    </div>
                    <div class="card-overlay">
                        <h5>View More Info</h5>
                    </div>
                </div>
            </a>
        </div>
        <div class="col-sm-6 col-lg-4 mb-4">
            <a href="https://joannab.ca/todo-app" class="text-decoration-none">
                <div class="card card-link h-100">
                    <img class="card-img-top d-block" src="assets/todo-app-screenshot.png" alt="Todo App Screenshot">
                    <div class="card-body d-flex flex-column">
                        <h4 class="mb-0">Todo</h4>
                        <p class="card-text">A Todo app built on Angular.</p>
                        <div class="mt-auto">
                            <span class="badge badge-pill badge-secondary mr-1">Angular</span>
                            <span class="badge badge-pill badge-secondary mr-1">HTML</span>
                            <span class="badge badge-pill badge-secondary">CSS</span>
                        </div>
                    </div>
                    <div class="card-overlay">
                        <h5>View More Info</h5>
                    </div>
                </div>
            </a>
        </div>

        <!--<div class="col-sm-6 col-lg-4 mb-4">
            <a routerLink="dal-shhp" class="text-decoration-none">
                <div id="dal-shhp" class="card card-link h-100">
                    <img class="card-img-top d-block" src="assets/iphone-curriculum-maps.png" alt="Dal SHHP Curriculum Maps on iPhone">
                    <div class="card-body d-flex flex-column">
                        <h4 class="mb-0">Dalhousie SHHP Curriculum Maps</h4>
                        <p class="card-text">Curriculum mapping software.</p>
                        <div class="mt-auto">
                            <span class="badge badge-pill badge-secondary mr-1">PHP</span>
                            <span class="badge badge-pill badge-secondary mr-1">HTML</span>
                            <span class="badge badge-pill badge-secondary">CSS</span>
                        </div>
                    </div>
                    <div class="card-overlay">
                        <div class="card-overlay-inner">View More Info</div>
                    </div>
                </div>
            </a>
        </div>-->

        <!--<div class="col-sm-6 col-lg-4 mb-4">
            <a routerLink="claimit" class="text-decoration-none">
                <div class="card card-link h-100">
                    <img class="card-img-top d-block" src="assets/claimit-screenshot.png" alt="Claimit prototype screenshot">
                    <div class="card-body d-flex flex-column">
                        <h4 class="mb-0">ClaimIt</h4>
                        <p class="card-text">NS Small Claims Court Roadmap.</p>
                        <div class="mt-auto">
                            <span class="badge badge-pill badge-secondary mr-1">UI Design</span>
                            <span class="badge badge-pill badge-secondary">Prototyping</span>
                        </div>
                    </div>
                    <div class="card-overlay">
                        <div class="card-overlay-inner">View More Info</div>
                    </div>
                </div>
            </a>
        </div>-->

        <div class="col-sm-6 col-lg-4 mb-4">
            <a href="http://www.alphagoods.com" class="text-decoration-none">
                <div class="card card-link h-100">
                    <img class="card-img-top d-block" src="assets/korinthia-website-screenshot.png" alt="Korinthia (Alpha Trade) Website Screenshot">
                    <div class="card-body d-flex flex-column">
                        <h4 class="mb-0">Korinthia (Alpha Trade)</h4>
                        <p class="card-text">A website made with Vue.js.</p>
                        <div class="mt-auto">
                            <span class="badge badge-pill badge-secondary mr-1">Vue.js</span>
                            <span class="badge badge-pill badge-secondary mr-1">HTML</span>
                            <span class="badge badge-pill badge-secondary">CSS</span>
                        </div>
                    </div>
                    <div class="card-overlay d-flex align-content-center justify-content-center">
                        <div class="card-overlay-inner">View More Info</div>
                    </div>
                </div>
            </a>
        </div>

        <div class="col-sm-6 col-lg-4 mb-4">
            <a routerLink="digitalns" class="text-decoration-none">
                <div class="card card-link h-100">
                    <img class="card-img-top d-block" src="assets/digitalns-improved-wireframe.png" alt="Improved Digital NS wireframe">
                    <div class="card-body d-flex flex-column">
                        <h4 class="mb-0">Digital NS Website Analysis</h4>
                        <p class="card-text">Analyzing and improving the Digital NS website.</p>
                        <div class="mt-auto">
                            <span class="badge badge-pill badge-secondary mr-1">UI Design</span>
                            <span class="badge badge-pill badge-secondary">Wireframing</span>
                        </div>
                    </div>
                    <div class="card-overlay d-flex align-content-center justify-content-center">
                        <div class="card-overlay-inner">View More Info</div>
                    </div>
                </div>
            </a>
        </div>

        <div class="col-sm-6 col-lg-4 mb-4">
            <a routerLink="artwork" class="text-decoration-none">
                <div class="card card-link h-100">
                    <img class="card-img-top d-block" src="assets/art.png" alt="Stippled portrait of Frank Ocean">
                    <div class="card-body d-flex flex-column">
                        <h4 class="mb-0">Artwork</h4>
                        <p class="card-text">Stippled portraits by yours truly.</p>
                        <div class="mt-auto">
                            <span class="badge badge-pill badge-secondary mr-1">Art</span>
                            <span class="badge badge-pill badge-secondary">Design</span>
                        </div>
                    </div>
                    <div class="card-overlay d-flex align-content-center justify-content-center">
                        <div class="card-overlay-inner">View More Info</div>
                    </div>
                </div>
            </a>
        </div>
    </div>
</div>