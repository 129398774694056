import { Component } from '@angular/core';
import { faApple, faGithub, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { trigger, transition, state, animate, style } from '@angular/animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  faGithub = faGithub;
  faLinkedinIn = faLinkedinIn;
  faPlus = faPlus;

  /*public toggleScroll() {
    var bodyClasses = document.body.classList;
    bodyClasses.toggle("no-scroll");
  }*/
  
}

window.onscroll = function() {
  var resume = document.getElementById("resume");
  var welcome = document.getElementById("welcome");
  var body = document.getElementById("body");
  var nav = document.getElementById("nav");

  if (nav != null) {
    if (document.body.scrollTop > 15 || document.documentElement.scrollTop > 15) {
      nav.classList.add('floating-nav');
    } else {
      nav.classList.remove('floating-nav');
    }
  }

  /* for about page only */
  if (resume != null && welcome != null) {
    if (document.body.scrollTop > 415 || document.documentElement.scrollTop > 415) {
      resume.className = 'fade-in';
      welcome.className = 'fade-out';
      body.className = 'fade-bg-white';
      nav.className = 'navbar fixed-top navbar-expand-sm navbar-dark floating-nav switch-to-light';
    } else {
      resume.className = 'fade-out';
      welcome.className = 'fade-in';
      body.className = 'fade-bg-black';
      nav.className = 'navbar fixed-top navbar-expand-sm navbar-dark switch-to-dark';
    }
  }
};

/*window.onscroll = function() {
  var resume = document.getElementById("resume");
  var welcome = document.getElementById("welcome");
  var body = document.getElementById("body");
  var nav = document.getElementById("nav");

  if (resume != null && welcome != null) {
    if (document.body.scrollTop > 375 || document.documentElement.scrollTop > 375) {
      resume.className = 'fade-in';
      welcome.className = 'fade-out';
      body.className = 'fade-bg-white';
      nav.className = 'navbar fixed-top navbar-expand-sm navbar-dark floating-nav switch-to-light';
    } else {
      resume.className = 'fade-out';
      welcome.className = 'fade-in';
      body.className = 'fade-bg-black';
      nav.className = 'navbar fixed-top navbar-expand-sm navbar-dark floating-nav switch-to-dark';
    }
  }
};*/