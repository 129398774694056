<div class="content-container">
    <div class="text-center mb-5">
        <h1>Artwork</h1>
        <h6>Stippled portraits made using an 8.5 x 11 inch canvas and a Sharpie pen or the Apple Notes application on my iPad</h6>
    </div>
    <div class="row d-flex justify-content-center pt-3">
        <div class="col-sm-6 col-lg-4 my-auto">
            <div class="card">
                <div class="card-body">
                    <img src="assets/art/jay-z.png">
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-lg-4 my-auto">
            <div class="card">
                <div class="card-body">
                    <img src="assets/art/chet-faker.png">
                </div>
            </div>
        </div>

        <div class="col-sm-6 col-lg-4 my-auto">
            <div class="card">
                <div class="card-body">
                    <img src="assets/art/fka-twigs.png">
                </div>
            </div>
        </div>

        <div class="col-sm-6 col-lg-4 my-auto">
            <div class="card">
                <div class="card-body">
                    <img src="assets/art/frank-ocean.png">
                </div>
            </div>
        </div>

        <div class="col-sm-6 col-lg-4 my-auto">
            <div class="card">
                <div class="card-body">
                    <img src="assets/art/bill-nye.png">
                </div>
            </div>
        </div>
    </div>
</div>