<div class="content-container">
    <div class="text-center mb-5">
        <img class="logo m-0" src="assets/hereattendance/here-logo.png" alt="here! Attendance logo">
        <!--<h1>Here! Attendance</h1>-->
        <h6>Manage your classroom attendance</h6>
    </div>

    <!-- Overview Section -->
    <section class="mb-5">
        <h4>OVERVIEW</h4>
        <hr>

        Attendance can be spotty in labs and lectures. Even when students are supposed to receive credit for their attendance, sometimes the attendance sheet will go missing, or students will sign it when they walked in very late. We have conceptualized a solution that will use QR codes and GPS to verify a student’s attendance. The QR code will be generated and provided to the professor before the class, and they can display it to the students in whatever manner they prefer. The date, time and location of the students will be recorded when they scan the QR code to ensure honest reporting, and the professor will have access to the attendance record.
    </section>

    <!-- Design Section -->
    <section class="container p-0 mb-5">
        <h4>DESIGN</h4>
        <hr>
        <div class="mb-5">
            There are different views depending on the account that is signed in.
        </div>
            
        <h5>STUDENT VIEW</h5>
        <div class="row mb-5">
            <div class="col-lg-6">
                <img src="assets/hereattendance/student-view.png" class="pb-3">
            </div>
            <div class="col-lg-6 my-auto">
                <ol>
                    <li>The login screen of the app where the student can enter their university ID and password. The sign in button will verify the user entered credentials.</li>
                    <li>The attendance screen, where the student can view the courses they are enrolled in, and their overall attendance for each course.</li>
                    <li>The detailed attendance of the course a student is enrolled in, per lecture.</li>
                    <li>The navigation menu for quick access to QR scanner or Attendance History.</li>
                    <li>Through the camera access, the application switches to the camera in order to allow the user to can the QR code.</li>
                    <li>The confirmation screen notifies the user that their attendance has been
                    successfully recorded.</li>
                </ol>
            </div>
        </div>
        <h5>PROFESSOR VIEW</h5>
        <div class="row mb-5">
            <div class="col-lg-6 my-auto">
                <ol>
                    <li>The login screen of the app where the professor can enter their university ID and password. The sign in button will verify the professor entered valid credentials.</li>
                    <li>The attendance screen, where the professor can view the courses they instruct, and their overall attendance for each course.</li>
                    <li>The lecture screen displays the overall attendance of the students that attended the class and scanned the QR code using our application.</li>
                    <li>The drawer that shows the attendance history (list of courses the instructor instructs) and allows the professor to log out.</li>
                    <li>The QR that gets generated automatically by the application.</li>
                    <li>The lecture screen allows the professor to generate a new QR code for each lecture, also to see their previous attendance records for a specific lecture.</li>
                </ol>
            </div>
            <div class="col-lg-6">
                <img src="assets/hereattendance/professor-view.png" class="pb-3">
            </div>
        </div>
    </section>

    <!-- Code Section -->
    <section class="mb-5">
        <h4>CODE</h4>
        <hr>

        This Android application was made with a team of 5 as our final project in CSCI 4176: Mobile Computing - Fall 2018 at Dalhousie University. We used Android Studio and GitHub. You can find the code on GitHub <a href="https://github.com/jbstks/CSCI4176-HereAttendance">here</a>.
    </section>
</div>