<div class="content-container">
    <div class="text-center mb-5">
        <img class="logo m-0" src="assets/maximegalonline/maximegalonline-logo.png" alt="MaximegalOnline logo">
        <!--<h1>Here! Attendance</h1>-->
        <h6>Course management at the University of Maximegalon</h6>
    </div>

    <!-- Overview Section -->
    <section class="mb-5">
        <h4>OVERVIEW</h4>
        <hr>

        <small class="text-muted">This project was designed for a fictional university.</small>
        <p>
            MaximegalOnline is an Android application that allows for University of Maximegalon students to list and enroll for a course offered on their campus. It has the following features:
        </p>
        <ul>
            <li>The ability to register for a specific course from a list of offered courses</li>
            <li>A view that allows a specific student to see their weekly schedule</li>
            <li>A view that lets students check the details for a specific course</li>
            <li>A view that allows the student to see how many spots are still left on the course</li>
            <li>A notification that warns and stops a student registering for a course if there is a time conflict with another enrolled course</li>
        </ul>
    </section>

    <!-- Design Section -->
    <section class="container p-0 mb-5">
        <h4>DESIGN</h4>
        <hr>
        <p>Since we were designing an application for a fictional university, we didn't have much to go off of in terms of colour palettes and branding. So I decided to design my own.</p>
        
        <p>Knowing that the University of Maximegalon is based off the esteemed fictional university in the book, <i>The Hitchhiker's Guide to the Galaxy</i>, I decided to go with a science theme. As you can see, the logo went through several stages.</p>

        <div class="row list-logos">
            <div class="col-6 col-lg-3 img-timeline"><img src="assets/maximegalonline/maximegalon-logo-old1.png" alt="uMaximegalon old first logo"></div>
            <div class="col-6 col-lg-3 img-timeline"><img src="assets/maximegalonline/maximegalon-logo-old2.png" alt="uMaximegalon old second logo"></div>
            <div class="col-6 col-lg-3 img-timeline"><img src="assets/maximegalonline/maximegalon-logo-old3.png" alt="uMaximegalon old third logo"></div>
            <div class="col-6 col-lg-3 img-timeline"><img src="assets/maximegalonline/maximegalon-logo-final.png" alt="uMaximegalon final logo"></div>
        </div>
        <p>My initial idea was to replace nucleus in the middle of the atom with a computer of some kind. What I tried in the first iteration looked bad, so I ditched it in the second iteration.</p>
        <p>The second and third iteration of the logo are very similar. I knew I needed to add colour so that the application colour palette wasn't black and white. I also decided to incorporate the computer into the atom in such a way that it didn't look awkward.</p>
        <p>To represent the strength of this prestigious insitution, blue was the colour of choice as the main color. To represent the quality and to just act as a complement to the blue, a golden yellow was used as the accent.</p>
        <p>The final design uses Material design to tie it in as an Android application.</p>
    </section>

    <!-- Code Section -->
    <section class="mb-5">
        <h4>CODE</h4>
        <hr>

        This Android application was made with a team of 6 as our final project in CSCI 3130: Software Engineering - Summer 2018 at Dalhousie University. We used Android Studio and GitHub. You can find the code on GitHub <a href="https://github.com/jbstks/CSCI3130-Project-MaximegalOnline">here</a>.
    </section>
</div>