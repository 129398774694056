import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DigitalnsComponent } from './digitalns/digitalns.component';
import { ClaimitComponent } from './claimit/claimit.component';
import { PortfolioListComponent } from './portfolio-list/portfolio-list.component';

import { PortfolioRoutingModule } from './portfolio-routing.module';
import { HereattendanceComponent } from './hereattendance/hereattendance.component';
import { ArtworkComponent } from './artwork/artwork.component';
import { MaximegalonlineComponent } from './maximegalonline/maximegalonline.component';



@NgModule({
  declarations: [
    DigitalnsComponent,
    ClaimitComponent,
    PortfolioListComponent,
    HereattendanceComponent,
    ArtworkComponent,
    MaximegalonlineComponent
  ],
  imports: [
    CommonModule,
    PortfolioRoutingModule
  ]
})
export class PortfolioModule { }
