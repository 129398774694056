<nav class="navbar fixed-top navbar-expand-sm navbar-dark" id="nav">
    <div class="container">
        <a class="navbar-brand" routerLink="about"><img class="logo" id="logo" src="assets/joanna-white.png"></a>
        <!-- (click)="toggleScroll();" -->
        <button id="toggler" class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <!--<span class="navbar-toggler-icon"></span>-->
            <fa-icon [icon]="faPlus"></fa-icon>
          </button>
        <div class="collapse navbar-collapse" id="navbarNav">
            <div class="navbar-nav">
                <!-- (click)="toggleScroll();" -->
                <a class="nav-item nav-link" routerLink="about" routerLinkActive="active"><a data-toggle="collapse" data-target="#navbarNav">ABOUT</a></a>
                <a class="nav-item nav-link" routerLink="portfolio" routerLinkActive="active"><a data-toggle="collapse" data-target="#navbarNav">PORTFOLIO</a></a>
                <!-- Disable contact for now as it is not needed -->
                <!--<a class="nav-item nav-link" routerLink="contact" routerLinkActive="active">CONTACT</a>-->
            </div>
        </div>
    </div>
</nav>

<div class="container">
    <router-outlet></router-outlet>
</div>

<footer class="container">
    <hr>
    <div id="footer-content" class="d-flex justify-content-between footer-content">
        <span class="small text-muted mt-auto mb-auto">Copyright &copy; 2020 Joanna Bistekos.</span>
        <div class="relevant-links">
            <a href="https://github.com/jbstks" class="btn btn-link"><fa-icon [icon]="faGithub"></fa-icon></a> <a href="https://www.linkedin.com/in/jbstks/" class="btn btn-link"><fa-icon [icon]="faLinkedinIn"></fa-icon></a>
        </div>
    </div>
</footer>