import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-claimit',
  templateUrl: './claimit.component.html',
  styleUrls: ['./claimit.component.css']
})
export class ClaimitComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    var body = document.getElementById("body");
    var nav = document.getElementById("nav");
  
    //body.className = 'fade-bg-black';
    //nav.className = 'navbar fixed-top navbar-expand-sm navbar-dark switch-to-dark';
    body.className = 'fade-bg-white';
    nav.className = 'navbar fixed-top navbar-expand-sm navbar-dark switch-to-light';
  }

}
