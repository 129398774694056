import { Component, OnInit } from '@angular/core';
import { faApple, faGithub, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { jobs } from './jobs';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  faApple = faApple;
  faGithub = faGithub;
  faLinkedinIn = faLinkedinIn;
  faChevronDown = faChevronDown;
  jobs = jobs;

  constructor() { }

  ngOnInit() {
    var body = document.getElementById("body");
    var nav = document.getElementById("nav");
    var welcome = document.getElementById("welcome");
  
    welcome.className = 'fade-in';
    body.className = 'fade-bg-black';
    nav.className = 'navbar fixed-top navbar-expand-sm navbar-dark switch-to-dark';
  }

}

/*window.onscroll = function() {
  var resume = document.getElementById("resume");
  var welcome = document.getElementById("welcome");
  var body = document.getElementById("body");
  var nav = document.getElementById("nav");

  if (resume != null && welcome != null) {
    if (document.body.scrollTop > 375 || document.documentElement.scrollTop > 375) {
      resume.className = 'fade-in';
      welcome.className = 'fade-out';
      body.className = 'fade-bg-white';
      nav.className = 'navbar fixed-top navbar-expand-sm navbar-dark floating-nav switch-to-light';
    } else {
      resume.className = 'fade-out';
      welcome.className = 'fade-in';
      body.className = 'fade-bg-black';
      nav.className = 'navbar fixed-top navbar-expand-sm navbar-dark floating-nav switch-to-dark';
    }
  }
};*/