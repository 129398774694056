import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-portfolio-list',
  templateUrl: './portfolio-list.component.html',
  styleUrls: ['./portfolio-list.component.css']
})
export class PortfolioListComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    var body = document.getElementById("body");
    var nav = document.getElementById("nav");
    var portfolio = document.getElementById("portfolio");
  
    body.className = 'fade-bg-white';
    nav.className = 'navbar fixed-top navbar-expand-sm navbar-dark switch-to-light';
    portfolio.className = 'fade-in';
  }

  /*public openMoreInfo() {
    var card = document.getElementById("dal-shhp");
    card.style.width = '100vw';
  }*/
}
